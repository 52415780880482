import axios from 'axios'

import batteryImage from './assets/battery.png'
import whatsappLogo from './assets/whatsapp-logo.png'

let makeData
let modelData

let make
let model
let year

const getSelect = key => document.querySelector(`select[name=${key}]`)

axios.get('data/makes.json')
  .then(({ data }) => { setSelect('make', data) })

getSelect('make').addEventListener('change', (e) => {
  clearSelect('model')
  clearSelect('year')
  clearResult()

  make = e.target.value
  if (!make) { return }

  axios.get(`data/makes/${make}.json`)
    .then(({ data }) => {
      makeData = data
      setSelect('model', Object.keys(makeData))
    })
})

getSelect('model').addEventListener('change', (e) => {
  clearSelect('year')
  clearResult()

  model = e.target.value
  if (!model) { return }

  modelData = makeData[model]
  setSelect('year', Object.keys(modelData))
})

getSelect('year').addEventListener('change', (e) => {
  clearResult()

  year = e.target.value
  if (!year) { return }

  setResult(modelData[year] || '')
})

const setSelect = (key, values) => {
  const select = getSelect(key)
  const options =
    values.map(value => `<option value="${value}">${value}</option>`)

  select.innerHTML = [getEmptyOption(key), ...options]
  select.removeAttribute('disabled')
}

const clearSelect = key => {
  const select = getSelect(key)

  select.innerHTML = getEmptyOption(key)
  select.setAttribute('disabled', 'disabled')
}

const setResult = result => {
  const resultDiv = document.querySelector('#result')
  resultDiv.innerHTML = renderResult(result)

  const resultClose = document.querySelector('[data-target=close-button]')

  if (resultClose) {
    resultClose.addEventListener('click', closeResult)
  }
}

const clearResult = () => {
  const resultClose = document.querySelector('[data-target=close-button]')

  if (resultClose) {
    resultClose.removeEventListener('click', closeResult)
  }

  const resultDiv = document.querySelector('#result')
  resultDiv.innerHTML = ''
}

const closeResult = (event) => {
  event.preventDefault()

  year = null
  getSelect('year').value = ''
  clearResult()
}

const getEmptyOption = key => ({
  make: `<option value="">MARCA</option>`,
  model: `<option value="">MODELO</option>`,
  year: `<option value="">AÑO</option>`
}[key])

const renderResult = battery => `
  <div class="result-modal">
    <div class="result-card">
      <div class="result-actions">
        <a data-target="close-button" class="result-close" href="#">
          &times;
        </a>
      </div>

      <div class="result-content">
        <img class="result-img" src="${batteryImage}" alt="battery" />
        <div>
          <div class="result-subtitle">${make} ${model} ${year}</div>
          <div class="result-title">${battery}</div>
          <hr class="result-separator">
          <img src="${whatsappLogo}" style="width:12px;margin-bottom:-2px"/>
          <a href="${contactUrl}" class="result-contact">${resultText}</a>
        </div>
      </div>
    </div>
  </div>
`

const resultText = "+58 412 331 53 05"
const contactUrl = "https://wa.me/584123315305"

// make = "CHERY"
// model = "ORINOCO"
// year = "2015"
//
// setResult("36MR650")
